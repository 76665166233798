import React from 'react';

const HoursTemplate = ({ data }) => {
  const { daysHours } = data.data;

  return data ? (
    <div className="tile is-parent">
      <article className="tile is-child box">
        <div className="content">
          <p className="title">Consultation Hours</p>
          <div className="content">
            <table className="table">
              <thead>
                <th>Day</th>
                <th>Opening Hours</th>
              </thead>
              <tbody>
                <tr
                  style={{
                    height: '50px'
                  }}
                >
                  <td>Monday</td>
                  <td>{daysHours.monday}</td>
                </tr>
                <tr style={{ height: '50px' }}>
                  <td>Tuesday</td>
                  <td>{daysHours.tuesday}</td>
                </tr>
                <tr
                  style={{
                    height: '50px'
                  }}
                >
                  <td>Wednesday</td>
                  <td>{daysHours.wednesday}</td>
                </tr>
                <tr style={{ height: '50px' }}>
                  <td>Thursday</td>
                  <td>{daysHours.thursday}</td>
                </tr>
                <tr
                  style={{
                    height: '50px'
                  }}
                >
                  <td>Friday</td>
                  <td>{daysHours.friday}</td>
                </tr>
                <tr style={{ height: '50px' }}>
                  <td>Saturday</td>
                  <td>{daysHours.saturday}</td>
                </tr>
                <tr
                  style={{
                    height: '50px'
                  }}
                >
                  <td>Sunday & Public Holidays</td>
                  <td>
                    <strong>{daysHours.sunday}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </article>
    </div>
  ) : (
    <div></div>
  );
};

const Hours = props => {
  if (!props) return props;
  return <HoursTemplate data={props} />;
};

export default Hours;
