import React from 'react';
import Layout from '../components/Layout';
import DoctorProfile from '../img/DoctorProfile.png';
import HeroCover from '../img/ClarkeQuay.jpg';
import Hours from '../components/Hours';
import { toast } from 'react-toastify';
import { graphql } from 'gatsby';
import styled from 'styled-components';

const StyledPara = styled.p`
  font-size: 18px;
  @media screen and (max-width: 400px) {
    font-size: 12px;
  }
`;

const IndexPage = ({ data }) => {
  const notify = () => {
    if (data && data.Notifications) {
      const { notify } = data.Notifications.edges[0].node.frontmatter;
      if (notify.toggleOn && notify.type && notify.message)
        toast.warn(notify.message, {
          position: toast.POSITION.TOP_CENTER
        });
      else if (notify.toggleOn && !notify.type && notify.message)
        toast.info(notify.message, {
          position: toast.POSITION.TOP_CENTER
        });
    }
  };
  return (
    <Layout>
      <section
        class="hero is-primary header-image is-medium"
        style={{
          backgroundColor: 'transparent',
          backgroundImage: `url(${HeroCover})`,
          maxHeight: '450px'
        }}
      >
        <div class="hero-body">
          <div class="container">
            <h1 class="title has-text-centered">Welcome To Jade Medical</h1>
            <div class="content has-text-centered">
              <StyledPara>
                Jade Medical Clinic is a GP clinic conveniently located in The
                Central shopping mall just next to Clark Quay MRT station. It
                provides a full range of medical services including
                vaccinations, travel and occupational medicine advice and
                statutory medical examinations for employment besides general
                consultations and treatment for acute and long term illnesses.
                It is helmed by Dr Leow Cheng Gek, a lady doctor who has special
                interest in women's health and medical treatment of skin
                conditions.
              </StyledPara>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '20px'
                }}
              >
                <a
                  class="button is-link is-light is-inverted"
                  href="/ourservices"
                >
                  Our Services
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        style={{
          marginTop: '20px',
          marginBottom: '20px',
          paddingLeft: '20px',
          paddingRight: '20px'
        }}
      >
        <div class="tile is-ancestor">
          <div class="tile is-vertical is-8">
            <div class="tile">
              <div class="tile is-parent is-vertical">
                <article class="tile is-child box">
                  <p class="title">Doctor's Profile</p>
                  <div class="columns">
                    <div className="column is-3">
                      <img src={DoctorProfile} alt="Dr Leow Cheng Gek" />
                      <p>
                        <strong>Dr Leow Cheng Gek</strong>
                      </p>
                      <p>MBBS (Singapore)</p>
                      <p>Dip Derm</p>
                      <p>Dip Occ Med</p>
                      <p>Dip Fam Med</p>
                    </div>
                    <div className="column is-9">
                      <p class="content">
                        Dr Leow Cheng Gek graduated from the medical faculty of
                        NUS in 1993. After completing her term in government
                        service, she joined a family medicine practice group in
                        1999. From an employee doctor, she became a partner and
                        subsequently a director as of 2003. Despite running a
                        busy medical practice, she was an active participant in
                        professional medical courses so as to upgrade herself in
                        order to provide the best care for her patients. Dr Leow
                        was awarded the Graduate Diploma in Family Practice
                        Dermatology (Singapore) in 2001, appointed a designated
                        medical examiner (DME) by the Civil Aviation Authority
                        of Singapore in 2002 and a designated factory doctor
                        (DFD) in 2003 upon completion of the Diploma in
                        Occupational Medicine. Last but not least, she was
                        awarded the Graduate Diploma in Family Medicine in 2008.
                        Dr Leow established Jade Medical Clinic in 2010 as an
                        embodiment of her philosophy to provide quality,
                        holistic primary medical care to the entire family in a
                        friendly and caring environment, as she is experienced
                        in attending to both children and adult medical
                        conditions. Her experience in corporate medical practice
                        also enables her to handle a wide range of issues
                        specific to the workplace and workforce.
                      </p>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </div>
          {data && data.OpeningHours && (
            <Hours data={data.OpeningHours.edges[0].node.frontmatter} />
          )}
          {notify()}
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  {
    OpeningHours: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "openingHours" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            daysHours {
              monday
              tuesday
              wednesday
              thursday
              friday
              saturday
              sunday
            }
          }
        }
      }
    }
    Notifications: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "notification" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            notify {
              type
              message
              toggleOn
            }
          }
        }
      }
    }
  }
`;

/* IndexPage.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array
  })
}; */

export default IndexPage;
